import { deleteNotification } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Table, TableColumnsType, message } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BasicPopup from '../Common/BasicPopup';

interface NotificationResponseDataProps {
  currentPage: number;
  data: NotificationResponse | null;
  getNotificationData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  notificationType: EnumPresenter;
  title: string;
  content: string;
  reservationDate?: string;
  isSend: boolean;
  sendCount: number;
  sendSuccessCount: number;
  redirectUrl: string;
  isDelete: boolean;
  createdDate: string;
  createdBy: string;
}

const NotificationSearchResult = ({ currentPage, data, getNotificationData }: NotificationResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    { title: '번호', dataIndex: 'index' },
    { title: '타입', dataIndex: 'notificationType', render: (text, record) => record.notificationType?.desc || '-' },
    { title: '제목', dataIndex: 'title' },
    { title: '알림내용', dataIndex: 'content' },
    { title: '예약시간', dataIndex: 'reservationDate' },
    {
      title: '전송여부',
      dataIndex: 'isSend',
      render: (text, record) => (record.isSend ? '전송' : '미전송'),
    },
    {
      title: '발송/성공 카운트',
      dataIndex: 'count',
      render: (text, record) => record.sendCount + '/' + record.sendSuccessCount,
    },
    { title: '리다이렉트 url', dataIndex: 'redirectUrl' },
    { title: '등록일자', dataIndex: 'createdDate' },
    { title: '등록자', dataIndex: 'createdBy' },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete == true ? '삭제' : '미삭제'),
    },
  ];

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      notificationType: item.notificationType || '',
      title: item.title || '',
      content: item.contents || '',
      reservationDate: item.reservationDate || '',
      isSend: item.isSend,
      sendCount: item.sendCount,
      sendSuccessCount: item.sendSuccessCount,
      redirectUrl: item.redirectUrl || '-',
      isDelete: item.isDelete,
      createdDate: item.createdDate || '-',
      createdBy: item.createdBy || '-',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const navigate = useNavigate();
  const onMoveRegisterPage = () => {
    navigate('/notification/form');
  };

  const deleteNoticeMutation = useMutation(deleteNotification, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getNotificationData();
      setSelectedRowKeys([]);
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      deleteNoticeMutation.mutate(String(firstSelectedId));
      getNotificationData();
    } else {
      message.error('알림이 선택되지 않았어요. 삭제할 알림을 선택해주세요.');
    }
  };

  const showConfirm = () => {
    setIsOpenPopup(true);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const rowProps = (record: DataType) => ({
    onClick: () => {
      navigate(`/notification/${record.id}`, {
        state: { notificationData: data?.data?.filter((item, index) => item.id === record.id)[0] },
      });
    },
  });

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 요청건수</span> {data?.totalElements}개
          </div>
          <div>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onRow={rowProps}
          rowSelection={rowSelection}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
    </>
  );
};

export default NotificationSearchResult;
