import React, { useEffect, useState } from 'react';

import { getLicenses } from '@/api/public';
import LicenseFilterSelector from '@/components/License/LicenseFilterSelector';
import LicenseSearchResult from '@/components/License/List/LicenseSearchResult';
import { LOOKUP } from '@/const/license';
import { Pagination } from 'antd';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const LicenseList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);

  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedLicenseFilterValue>({
    sellerKeyword: null,
    truckNumber: null,
    status: null,
    locate: null,
    tons: null,
    year: null,
    licenseSalesType: null,
    licenseType: null,
    useClassification: null,
    productNumber: null,
    licenseCounselStatus: null,
    ascending: false,
  });

  const [currentPage, setCurrentPage] = useState<number>(1);

  const getLicenseData = async () => {
    const {
      sellerKeyword,
      truckNumber,
      status,
      locate,
      tons,
      year,
      licenseSalesType,
      licenseType,
      useClassification,
      productNumber,
      licenseCounselStatus,
      ascending,
    } = selectedFilterValue;
    const queryParams = new URLSearchParams({
      sellerKeyword: sellerKeyword || '',
      truckNumber: truckNumber || '',
      status: status || '',
      locate: locate || '',
      tons: tons ? String(tons) : '',
      year: year ? String(year) : '',
      licenseSalesType: licenseSalesType || '',
      licenseType: licenseType || '',
      useClassification: useClassification || '',
      productNumber: productNumber ? String(productNumber) : '',
      licenseCounselStatus: licenseCounselStatus ? licenseCounselStatus : '',
      ascending: String(ascending),
      page: String(currentPage),
      size: '10',
    });
    const response = await getLicenses(queryParams);
    return response.data;
  };

  const {
    data: searchResultData,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-license-data', selectedFilterValue, currentPage], getLicenseData, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
  });

  const handlePaginationChange = (page: number) => {
    if (page === 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
    } else {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: String(page) });
    }
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  useEffect(() => {
    setCurrentPage(pageFromQuery);
  }, [searchParams]);

  return (
    <>
      <h2 className="text-gray-8">
        번호판 관리 / <span className="font-semibold">번호판 조회</span>
      </h2>
      <LicenseFilterSelector
        menuType={LOOKUP}
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
      />
      <LicenseSearchResult
        currentPage={currentPage}
        data={searchResultData}
        getLicenseData={() => refetch()}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default LicenseList;
