import { getProducts } from '@/api/public';
import SpeedFilterSelector from '@/components/Vehicles/Speed/SpeedFilterSelector';
import SpeedSearchResult from '@/components/Vehicles/Speed/SpeedSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const SpeedList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);

  const restoredParam = new URLSearchParams(sessionStorage.getItem('speedListParam') || '');
  const productNumberString = restoredParam.get('productNumber');
  const productNumber = productNumberString !== null ? parseInt(productNumberString, 10) : null;

  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedFilterValue>({
    sellerKeyword: restoredParam.get('sellerKeyword') || null,
    productNumber: productNumber,
    status: restoredParam.get('productsStatus') || null,
    productPurchaseManageStatus: restoredParam.get('productPurchaseManageStatus') || null,
    startDate: restoredParam.get('startDate') || null,
    endDate: restoredParam.get('endDate') || null,
    isDelete: restoredParam.get('isDelete') || '',
    externalClientsName: restoredParam.get('externalClientsName') || '',
  });

  const [ascending, setAscending] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchTruckData = async () => {
    const {
      sellerKeyword,
      productNumber,
      truckNumber,
      status,
      productPurchaseManageStatus,
      startDate,
      endDate,
      isDelete,
      externalClientsName,
    } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      sellerKeyword: sellerKeyword || '',
      productNumber: productNumber ? String(productNumber) : '',
      truckNumber: truckNumber || '',
      productsStatus: status || '',
      type: 'SPEED',
      productPurchaseManageStatus: productPurchaseManageStatus || '',
      startDate: startDate || '',
      endDate: endDate || '',
      sortType: 'SPEED_REQUEST_TIME',
      ascending: String(ascending),
      isDelete: isDelete ? String(isDelete) : '',
      externalClientsName: externalClientsName || '',
      page: String(currentPage) || '1',
      size: '10',
    });

    sessionStorage.setItem('speedListParam', queryParams.toString());
    const response = await getProducts(queryParams);
    return response.data;
  };

  const {
    data: searchResultData,
    isLoading,
    isError,
    refetch,
  } = useQuery(['truck-speed-data', selectedFilterValue, currentPage, ascending], fetchTruckData, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
  });

  const handlePaginationChange = (page: number) => {
    if (page === 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
    } else {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: String(page) });
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  useEffect(() => {
    setCurrentPage(pageFromQuery);
  }, [searchParams]);

  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 매입견적 차량</h2>
      <h3 className="text-gray-8">
        차량관리 / <span className="font-semibold">매입견적 차량</span>
      </h3>
      <SpeedFilterSelector selectedFilterValue={selectedFilterValue} setSelectedFilterValue={setSelectedFilterValue} />
      <SpeedSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getTruckData={() => refetch()}
        ascending={ascending}
        setAscending={setAscending}
      />
      <Pagination
        current={currentPage || 1}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};
export default SpeedList;
