import { patchProductsDetail } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import SortingButton from '@/components/Common/SortingButton';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import {
  convertToSalesPeopleOptions,
  convertToSelectOptions,
  formatNumberWithComma,
  getFormatDate,
} from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface StatusChangeRequest {
  id: string;
  productPurchaseManageStatus: string;
}

const SpeedSearchResult = ({
  selectedFilterValue,
  data,
  getTruckData,
  ascending,
  setAscending,
}: ProductResponseDataProps) => {
  const onclickSortingButton = () => {
    if (setAscending) {
      setAscending(!ascending);
    }
  };
  const columns: TableColumnsType<ProductListDataType> = [
    { title: '제시번호', dataIndex: 'productsNumber' },
    { title: '차량명', dataIndex: 'truckName' },
    { title: '차량번호', dataIndex: 'truckNumber' },
    { title: '상태', dataIndex: 'status' },
    { title: '제조사', dataIndex: 'manufacturerName' },
    { title: '모델', dataIndex: 'modelName' },
    { title: '연식', dataIndex: 'firstRegistrationDate' },
    { title: '적재함 길이', dataIndex: 'loadedInnerLength' },
    { title: '변속기', dataIndex: 'transmission' },
    { title: '주행거리', dataIndex: 'distance' },
    { title: '판매가격', dataIndex: 'price' },
    {
      title: <SortingButton title="매입견적 요청일자" isAscending={ascending} onclick={onclickSortingButton} />,
      dataIndex: 'speedRequestTime',
    },
    {
      title: '관리 담당자',
      dataIndex: 'salesPeople',
      render: (text, record) => (
        <>
          <Select
            className="mr-4 my-2"
            style={{ width: 180 }}
            placeholder="관리 담당자"
            optionFilterProp="children"
            options={selectOptions.salesPeople}
            value={record?.salesPeople}
            onChange={(value) => onChangeSelect(value, record)}
            onClick={(e) => e.stopPropagation()}
          ></Select>
        </>
      ),
    },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete == true ? '삭제' : '미삭제'),
    },
    {
      title: '더운반 차량 여부',
      dataIndex: 'isTheUnbanProduct',
      render: (text, record) => (record.isTheUnbanProduct == true ? '더운반' : '자체'),
    },
    {
      title: '관리상태',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <Select
          value={record.productManage?.status?.desc}
          options={selectOptions.productPurchaseManageStatus}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
          onClick={(e) => e.stopPropagation()}
        ></Select>
      ),
    },
    {
      title: '관리메모',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <>
          {record?.productManage?.comment ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record?.productManage?.comment}
              </p>

              <Button onClick={(e) => handlePurchaseManageButtonClick(e, record)}>수정</Button>
            </>
          ) : (
            <Button onClick={(e) => handlePurchaseManageButtonClick(e, record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const [productPurchaseManageFilterEnum, setproductPurchaseManageFilterEnum] = useAtom(productPurchaseManageEnumAtom);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);
  const [isPurchaseManageModalIsOpen, setPurchaseManageModalIsOpen] = useState(false);
  const [purchaseManageComment, setPurchaseManageComment] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [statusRequest, setStatusRequest] = useState<StatusChangeRequest>({ id: '', productPurchaseManageStatus: '' });
  const [productFilterEnum] = useAtom(productsEnumAtom);
  const [isOpenSalesPeopleChangePopup, setIsOpenSalesPeopleChangePopup] = useState(false);
  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });

  const onChangeSelect = (value: string, record: ProductListDataType) => {
    const request = {
      id: String(record.id),
      salesPeopleId: value,
    };
    setBasicPopupData({
      title: '담당자를 변경하시겠어요?',
      handleOk: () => onClickSalesPeopleChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenSalesPeopleChangePopup(true);
  };
  const onClickCancelButton = () => {
    setIsOpenSalesPeopleChangePopup(false);
  };

  const onClickSalesPeopleChangeButton = (requestData: { id: string; salesPeopleId: string }) => {
    patchSalesPeopleModifyMutation.mutate(requestData);
  };

  const patchSalesPeopleModifyMutation = useMutation(
    (requestData: { id: string; salesPeopleId: string }) => patchProductsDetail(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('담당자가 변경되었습니다.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenSalesPeopleChangePopup(false);
      },
    },
  );

  const handlePurchaseManageButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, data: ProductListDataType) => {
    setPurchaseManageComment(data.productManage?.comment);
    setSelectedProductId(String(data.id));
    setPurchaseManageModalIsOpen(true);
    e.stopPropagation();
  };

  const handlePurchaseManageModalClose = () => {
    setPurchaseManageModalIsOpen(false);
  };

  const patchPurchaseManageCommentMutation = useMutation(
    (requestData: { id: string; productPurchaseManageComment: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('관리메모가 작성되었어요.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const patchPurchaseManageStatusMutation = useMutation(
    (requestData: { id: string; productPurchaseManageStatus: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('상세 정보가 수정되었습니다.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const handleStatusChange = (value: string, record: ProductListDataType) => {
    setIsOpenPopup(true);
    const request = {
      id: String(record.id),
      productPurchaseManageStatus: value,
    };
    setStatusRequest(request);
  };

  const onClickStatusChangeButton = () => {
    patchPurchaseManageStatusMutation.mutate(statusRequest);
    setIsOpenPopup(false);
  };

  const handlePurchaseManageSave = () => {
    if (selectedProductId) {
      const request: any = {};
      request.id = selectedProductId;
      request.productPurchaseManageComment = purchaseManageComment;
      patchPurchaseManageCommentMutation.mutate(request);
    }
    setPurchaseManageModalIsOpen(false);
  };

  const onChangeComment = (comment: string) => {
    setPurchaseManageComment(comment);
  };

  const convertProductsPurchaseManageFilterInfoToSelectOptions = (
    productPurchaseManageFilterEnum: ProductPurchaseManageFilterInfoType,
    productFilterEnum: ProductsFilterInfoType,
  ) => {
    return {
      productPurchaseManageStatus: convertToSelectOptions(productPurchaseManageFilterEnum.status),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
    };
  };

  const selectOptions = convertProductsPurchaseManageFilterInfoToSelectOptions(
    productPurchaseManageFilterEnum,
    productFilterEnum,
  );
  const updatedTableData =
    data?.data?.map((item) => {
      const [date, time] = item.speedRequestTime ? item.speedRequestTime.split(' ') : ['', ''];

      return {
        id: item.id,
        key: item.id,
        productsNumber: item.productsNumber,
        truckName: item.truckName,
        truckNumber: item.truckNumber,
        status: item.status?.desc || '',
        manufacturerName: item.manufacturerName,
        modelName: item.modelName,
        firstRegistrationDate: getFormatDate(String(item.firstRegistrationDate), ['year', 'month'], true),
        loadedInnerLength: item.loadedInnerLength,
        transmission: item.transmission?.desc || '',
        distance: formatNumberWithComma(String(item.distance)),
        price: item.price,
        speedRequestTime: `${date}\n${time}` || '',
        productPurchaseManage: item.productPurchaseManage,
        salesPeople: item.salesPeople?.id ? `${item.salesPeople?.name}(${item.salesPeople?.phoneNumber})` : '-',
        productManage: item.productPurchaseManage,
        createdDate: item.createdDate,
        isDelete: item.isDelete,
        isTheUnbanProduct: item.isTheUnbanProduct,
      };
    }) || [];

  const [tableData, setTableData] = useState<ProductListDataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowProps = (record: ProductListDataType) => ({
    onClick: () => {
      navigate(`/vehicles/speed/${record.id}`);
    },
  });

  const rowSelection: TableRowSelection<ProductListDataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체차량</span> {data?.totalElements}대
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onRow={rowProps}
          pagination={false}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="상태를 변경하겠습니까?"
        content=""
        handleOk={() => onClickStatusChangeButton()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
      <Modal
        title="메모 보기"
        open={isPurchaseManageModalIsOpen}
        onCancel={handlePurchaseManageModalClose}
        footer={[
          <Button key="close" onClick={handlePurchaseManageModalClose}>
            닫기
          </Button>,
          <Button key="save" type="primary" className="bg-[#1890ff] mx-2" onClick={handlePurchaseManageSave}>
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={purchaseManageComment}
          onChange={(e) => onChangeComment(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>

      <BasicPopup
        isOpen={isOpenSalesPeopleChangePopup}
        setIsOpen={setIsOpenSalesPeopleChangePopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={() => basicPopupData.handleOk()}
        handleCancel={() => basicPopupData.handleCancel()}
      ></BasicPopup>
    </>
  );
};

export default SpeedSearchResult;
