import React, { useEffect, useState } from 'react';

import { getProducts } from '@/api/public';
import VehicleSearchResult from '@/components/Vehicles/All/VehicleSearchResult';
import VehicleFilterSelector from '@/components/Vehicles/Common/FilterSelector';
import { ALL } from '@/const/vehicles';
import { Pagination } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const VehiclesListAll = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);

  const restoredParam = new URLSearchParams(sessionStorage.getItem('vehiclesListParam') || '');
  const productNumberString = restoredParam.get('productNumber');
  const productNumber = productNumberString !== null ? parseInt(productNumberString, 10) : null;
  const tonsNumberString = restoredParam.get('tons');
  const tons = tonsNumberString !== null ? parseInt(tonsNumberString, 10) : null;
  const loadedInnerLengthNumberString = restoredParam.get('loadedInnerLength');
  const loadedInnerLength = loadedInnerLengthNumberString !== null ? parseInt(loadedInnerLengthNumberString, 10) : null;

  const [ascending, setAscending] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortType, setSortType] = useState('');

  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedFilterValue>({
    sellerKeyword: restoredParam.get('sellerKeyword') || null,
    productNumber: productNumber,
    truckName: restoredParam.get('truckName') || null,
    truckNumber: restoredParam.get('truckNumber') || null,
    status: restoredParam.get('productsStatus') || null,
    productsType: restoredParam.get('type') || null,
    salesType: restoredParam.get('salesType') || null,
    manufacturerCategories: restoredParam.get('manufacturerId') || null,
    model: restoredParam.get('modelId') || null,
    tons: tons,
    loaded: restoredParam.get('loaded') || null,
    loadedInnerLength: loadedInnerLength,
    axis: restoredParam.get('axis') || null,
    transmission: restoredParam.get('transmission') || null,
    startDate: restoredParam.get('startDate') || String(dayjs('2023-12-01').format('YYYY-MM-DD')),
    endDate: restoredParam.get('endDate') || null,
    isDelete: restoredParam.get('isDelete') || '',
  });

  const fetchTruckData = async () => {
    const {
      sellerKeyword,
      productNumber,
      truckName,
      truckNumber,
      status,
      productsType,
      salesType,
      manufacturerCategories,
      model,
      tons,
      loaded,
      loadedInnerLength,
      axis,
      transmission,
      startDate,
      endDate,
      isDelete,
    } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      sellerKeyword: sellerKeyword ? sellerKeyword : '',
      productNumber: productNumber ? String(productNumber) : '',
      truckName: truckName || '',
      truckNumber: truckNumber || '',
      productsStatus: status || '',
      type: productsType || '',
      manufacturerId: manufacturerCategories || '',
      modelId: model || '',
      tons: tons ? String(tons) : '',
      loaded: loaded || '',
      loadedInnerLength: loadedInnerLength ? String(loadedInnerLength) : '',
      axis: axis || '',
      transmission: transmission || '',
      startDate: startDate || '',
      endDate: endDate || '',
      salesType: salesType || '',
      sortType: sortType || '',
      ascending: String(ascending),
      isDelete: isDelete ? String(isDelete) : '',
      page: String(currentPage) || '1',
      size: '10',
    });

    sessionStorage.setItem('vehiclesListParam', queryParams.toString());
    const response = await getProducts(queryParams);
    return response.data;
  };

  const {
    data: searchResultData,
    isLoading,
    isError,
    refetch,
  } = useQuery(['truck-all-data', selectedFilterValue, currentPage, ascending], fetchTruckData, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
  });

  const handlePaginationChange = (page: number) => {
    if (page === 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
    } else {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: String(page) });
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  useEffect(() => {
    setCurrentPage(pageFromQuery);
  }, [searchParams]);

  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 전체 차량</h2>
      <h3 className="text-gray-8">
        차량관리 / <span className="font-semibold">전체 차량</span>
      </h3>
      <VehicleFilterSelector
        salesType={ALL}
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
      />
      <VehicleSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getTruckData={() => refetch()}
        ascending={ascending}
        setAscending={setAscending}
        sortType={sortType}
        setSortType={setSortType}
      />
      <Pagination
        current={currentPage || 1}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default VehiclesListAll;
