import { getNotification } from '@/api/public';
import NotificationFilterSelector from '@/components/Notification/NotificationFilterSelector';
import NotificationSearchResult from '@/components/Notification/NotificationSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const NotificationList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);
  const notificationTypeFromQuery = searchParams.get('notificationType') || null;
  const isSendFromQuery = searchParams.get('isSend') || '';
  const reservationStartDateFromQuery = searchParams.get('reservationStartDate') || null;
  const reservationEndDateFromQuery = searchParams.get('reservationEndDate') || null;
  const ascendingFromQuery = searchParams.get('ascending') === 'true';

  const [currentPage, setCurrentPage] = useState<number>(pageFromQuery);

  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedNotificationFilterValue>({
    notificationType: notificationTypeFromQuery,
    isSend: isSendFromQuery,
    reservationStartDate: reservationStartDateFromQuery,
    reservationEndDate: reservationEndDateFromQuery,
    ascending: ascendingFromQuery,
  });

  const getNotificationData = async () => {
    const { notificationType, isSend, reservationStartDate, reservationEndDate, ascending } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      notificationType: notificationType || '',
      isSend: isSend || '',
      reservationStartDate: reservationStartDate || '',
      reservationEndDate: reservationEndDate || '',
      ascending: String(ascending),
      page: String(currentPage),
      size: '10',
    });
    const response = await getNotification(queryParams);
    return response.data;
  };

  const {
    data: searchResultData,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-notification-data', selectedFilterValue, currentPage], getNotificationData, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
  });

  const handlePaginationChange = (page: number) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: String(page) });
  };

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      notificationType: selectedFilterValue.notificationType || '',
      isSend: selectedFilterValue.isSend || '',
      reservationStartDate: selectedFilterValue.reservationStartDate || '',
      reservationEndDate: selectedFilterValue.reservationEndDate || '',
      ascending: String(selectedFilterValue.ascending),
      page: '1',
    });
    setCurrentPage(1);
  }, [selectedFilterValue]);

  useEffect(() => {
    setCurrentPage(pageFromQuery);
  }, [pageFromQuery]);

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: String(currentPage),
    });
  }, [currentPage]);

  return (
    <>
      <h2 className="hidden">알림관리 - 알림 내역</h2>
      <h3 className="text-gray-8">
        알림 관리 / <span className="font-semibold">알림내역</span>
      </h3>
      <NotificationFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
      />

      <NotificationSearchResult
        currentPage={currentPage}
        data={searchResultData}
        getNotificationData={() => refetch()}
      />

      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default NotificationList;
