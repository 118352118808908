import { Button, DatePicker, Input, InputNumber, Select } from 'antd';

import { jobEnumAtom } from '@/store/job';
import { convertToSelectOptions } from '@/utils/common';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React from 'react';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  selectedFilterValue: JobSelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<JobSelectedFilterValue>>;
}
const FilterSelector = ({ selectedFilterValue, setSelectedFilterValue }: SearchProps) => {
  const [jobFilterInfo, setJobFilterInfo] = useAtom(jobEnumAtom);

  const convertJobFilterInfoToSelectOptions = (jobFilterInfo: JobFilterInfoType) => {
    return {
      status: convertToSelectOptions(jobFilterInfo.status),
      salaryType: convertToSelectOptions(jobFilterInfo.salaryType),
      workingArea: convertToSelectOptions(jobFilterInfo.workingArea),
      workingDays: convertToSelectOptions(jobFilterInfo.workingDays),
      workingHours: convertToSelectOptions(jobFilterInfo.workingHours),
    };
  };

  const selectOptions = convertJobFilterInfoToSelectOptions(jobFilterInfo);

  const handleInputStringChange = (name: string, value: string | number) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      status: null,
      title: '',
      minTons: null,
      maxTons: null,
      salaryType: null,
      startDate: '',
      endDate: '',
      page: 1,
    });
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleInputNumberChange = (name: string, value: number | null) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  return (
    <div className="py-4">
      <div className="flex items-center mb-4">
        <Search
          className="mr-4"
          placeholder="제목 입력"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.title || ''}
          onChange={(e) => handleInputStringChange('title', e.target.value)}
        />

        <InputNumber
          className="mr-4 my-2"
          placeholder="최소 톤수"
          style={{ width: 100 }}
          value={selectedFilterValue?.minTons}
          onChange={(value) => handleInputNumberChange('minTons', value)}
          suffix="t"
          min={0}
        />

        <InputNumber
          className="mr-4 my-2"
          placeholder="최대 톤수"
          style={{ width: 100 }}
          value={selectedFilterValue?.maxTons}
          onChange={(value) => handleInputNumberChange('maxTons', value)}
          suffix="t"
          min={0}
        />

        <Select
          className="mr-4 my-2"
          style={{ width: 120 }}
          placeholder="상태"
          optionFilterProp="children"
          options={selectOptions.status}
          value={selectedFilterValue.status}
          onChange={(value) => onChangeSelect('status', value)}
        />

        <Select
          className="mr-4 my-2"
          style={{ width: 120 }}
          placeholder="급여 방식"
          optionFilterProp="children"
          options={selectOptions.salaryType}
          value={selectedFilterValue.salaryType}
          onChange={(value) => onChangeSelect('salaryType', value)}
        />
      </div>

      <div className="flex justify-between">
        <div>
          <span className="mr-2">등록일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>

        <div>
          <Button onClick={clear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

export default FilterSelector;
