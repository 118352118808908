import Error from '@/components/Error/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';

const UnauthorizationPage = () => {
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Error
          icon={ErrorOutlineIcon}
          title="권한 없는 페이지"
          description="페이지에 대한 접근 권한이 없습니다."
        ></Error>
      </div>
    </>
  );
};

export default UnauthorizationPage;
