import React, { useEffect, useState } from 'react';

import { getBannerList } from '@/api/public';
import FilterSelector from '@/components/Banner/FilterSelector';
import SearchResult from '@/components/Banner/SearchResult';
import { Pagination } from 'antd';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const BannerList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);

  const [selectedFilterValue, setSelectedFilterValue] = useState<BannerSelectedFilterValue>({
    bannerType: null,
    bannerLocation: null,
    display: 'true',
    page: 1,
  });

  useEffect(() => {
    setSelectedFilterValue((prev) => ({
      ...prev,
      page: pageFromQuery,
    }));
  }, [searchParams]);

  const getBannerData = async () => {
    const { bannerType, bannerLocation, display, page } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      bannerType: bannerType || '',
      bannerLocation: bannerLocation || '',
      display: display || '',
      page: String(page ? page : 1),
    });

    const response = await getBannerList(queryParams);
    return response.data;
  };

  const {
    data: searchResultData,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-banner-data', selectedFilterValue], getBannerData, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    setSelectedFilterValue((prev) => ({
      ...prev,
      page: pageFromQuery,
    }));
  }, [searchParams]);

  const handlePaginationChange = (page: number) => {
    if (page === 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
    } else {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: String(page) });
    }
  };

  return (
    <>
      <h3 className="text-gray-8">배너 관리</h3>
      <FilterSelector selectedFilterValue={selectedFilterValue} setSelectedFilterValue={setSelectedFilterValue} />
      <SearchResult selectedFilterValue={selectedFilterValue} data={searchResultData} getBannerData={() => refetch()} />
      <Pagination
        current={selectedFilterValue.page}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default BannerList;
