import { Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import NotificationReservationForm from './NotificationReservationForm';

const NotificationRegisterUpdate = () => {
  const { id } = useParams();

  let tabs: Tab[] = [
    {
      key: 'info',
      label: '알림 정보',
      children: <NotificationReservationForm />,
    },
  ];
  return (
    <>
      <h3 className="text-gray-8">
        알림 관리 /<span className="font-semibold"> 알림 {id ? '수정' : '등록'}</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default NotificationRegisterUpdate;
