import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Select } from 'antd';
import React from 'react';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  selectedFilterValue: SelectedGoogleReviewsFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedGoogleReviewsFilterValue>>;
  getGoogleReviewsData: () => void;
}

const GoogleReviewsFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getGoogleReviewsData,
}: SearchProps) => {
  const convertFilterInfoToSelectOptions = () => {
    return {
      isReply: [
        { value: 'true', label: '응답' },
        { value: 'false', label: '미응답' },
      ],
      starRating: [
        { value: '1', label: '1점' },
        { value: '2', label: '2점' },
        { value: '3', label: '3점' },
        { value: '4', label: '4점' },
        { value: '5', label: '5점' },
      ],
    };
  };
  const selectOptions = convertFilterInfoToSelectOptions();

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      authorName: '',
      starRating: '',
      isReply: '',
    });
  };

  const onChangeSelect = (name: string, value: string | number) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };
  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Search
          className="mr-4"
          placeholder="작성자명"
          allowClear
          onSearch={getGoogleReviewsData}
          style={{ width: 180 }}
          value={selectedFilterValue?.authorName || ''}
          onChange={(e) => handleInputStringChange('authorName', e.target.value)}
        />

        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="별점"
          optionFilterProp="children"
          options={selectOptions.starRating}
          value={selectedFilterValue?.starRating || null}
          onChange={(value) => onChangeSelect('starRating', value)}
        />

        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="응답여부"
          optionFilterProp="children"
          options={selectOptions.isReply}
          value={selectedFilterValue?.isReply || null}
          onChange={(value) => onChangeSelect('isReply', String(value))}
        />
      </div>
      <div className="w-full flex justify-end mb-2">
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getGoogleReviewsData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default GoogleReviewsFilterSelector;
