import { getGoogleReviews } from '@/api/public';
import GoogleReviewsFilterSelector from '@/components/GoogleReviews/GoogleReviewsFilterSelector';
import GoogleReviewsSearchResult from '@/components/GoogleReviews/GoogleReviewsSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const GoogleReviewsList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedGoogleReviewsFilterValue>({
    authorName: '',
    starRating: '',
    isReply: '',
  });
  const [searchResultData, setSearchResultData] = useState<GoogleReviewsResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getGoogleReviewsData = async (page?: number) => {
    const { authorName, starRating, isReply } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      authorName: authorName,
      starRating: starRating,
      isReply: isReply,
      page: String(page ? page : 1),
      size: '10',
    });
    const response = await getGoogleReviews(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getGoogleReviewsData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h2 className="hidden">구글 리뷰 관리 - 리뷰 내역</h2>
      <h3 className="text-gray-8">
        구글 리뷰 관리 / <span className="font-semibold">구글 리뷰 조회</span>
      </h3>

      <GoogleReviewsFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getGoogleReviewsData={() => getGoogleReviewsData(currentPage)}
      ></GoogleReviewsFilterSelector>

      <GoogleReviewsSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getGoogleReviewsData={() => getGoogleReviewsData(currentPage)}
      ></GoogleReviewsSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default GoogleReviewsList;
