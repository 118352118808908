import { patchPrices } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { Button, InputNumber, message, Modal, Table, TableColumnsType } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

interface DataType {
  id: number;
  key: number;
  tons: number;
  year: number;
  modelName: string;
  loaded: string;
  loadedInnerLength: number;
  axis: string;
  price: number;
}

const PricesSearchResult = ({ data, getPricesData }: PricesResponseDataProps) => {
  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      tons: item.pricesStandard.tons,
      year: item.pricesStandard.year,
      modelName: item.pricesStandard.model.name,
      loaded: item.pricesStandard.loaded.desc,
      loadedInnerLength: item.pricesStandard.loadedInnerLength,
      axis: item.pricesStandard.axis.desc,
      price: item.price,
    })) || [];
  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPriceInfo, setSelectedPriceInfo] = useState<{ id: number; price: number | null }>({
    id: 0,
    price: null,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickPriceUpdateButton = () => {
    if (!hasSelected) {
      message.error('선택된 시세 데이터가 없습니다.');
    } else {
      setSelectedPriceInfo({
        id: selectedRowKeys as unknown as number,
        price: data?.data?.find((record) => record.id == (selectedRowKeys as unknown as number))?.price || null,
      });

      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'id',
    },
    {
      title: '모델명',
      dataIndex: 'modelName',
    },
    {
      title: '톤수',
      dataIndex: 'tons',
    },
    {
      title: '연식',
      dataIndex: 'year',
    },
    {
      title: '가변축',
      dataIndex: 'axis',
    },
    {
      title: '적재함 종류',
      dataIndex: 'loaded',
    },
    {
      title: '적재함 길이(m)',
      dataIndex: 'loadedInnerLength',
    },
    {
      title: '시세금액(만원)',
      dataIndex: 'price',
    },
  ];

  const handleModalClose = () => {
    setIsOpenModal(false);
  };
  const patchPricesMutation = useMutation(
    (requestData: { id: number; price: number | null }) => patchPrices(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('시세 금액이 변경되었어요.', 2);
        getPricesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const handleModalSave = () => {
    patchPricesMutation.mutate(selectedPriceInfo);
    setIsOpenModal(false);
    setSelectedPriceInfo({ id: 0, price: null });
  };

  const onChangePrice = (inputPrice: number | null) => {
    setSelectedPriceInfo((prev) => ({
      ...prev,
      price: inputPrice,
    }));
  };
  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onClickPriceUpdateButton} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
          금액수정
        </Button>
      </div>
      <div className="py-4">
        <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <Modal
        title="시세 데이터 수정"
        open={isOpenModal}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            닫기
          </Button>,
          <Button key="save" type="primary" className="bg-[#1890ff] mx-2" onClick={handleModalSave}>
            저장
          </Button>,
        ]}
      >
        <InputNumber
          value={selectedPriceInfo.price === 0 ? null : selectedPriceInfo.price}
          className="mr-4 my-2"
          style={{ width: 180 }}
          onChange={(e) => onChangePrice(Number(e))}
          type="number"
          placeholder="금액 입력"
        />
      </Modal>
    </>
  );
};

export default PricesSearchResult;
