import apiManager from './ApiManager';

export const getProducts = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/products', { params: queryParams });
};

export const getProductsDetail = (productId?: string) => {
  return apiManager.get(`/admin/v1/products/${productId}`);
};

export const deleteProduct = (productId?: string) => {
  return apiManager.delete(`/admin/v1/products/${productId}`);
};

export const patchProductsDetail = (productId: string, request: any) => {
  return apiManager.patch(`/admin/v1/products/${productId}`, request);
};

export const postProducts = (request: any) => {
  return apiManager.post(`/admin/v1/products`, request);
};

export const getLicenses = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/license', { params: queryParams });
};

export const getLicensesDetail = (licenseId?: string) => {
  return apiManager.get(`/admin/v1/license/${licenseId}`);
};

export const deleteLicense = (licenseId?: string) => {
  return apiManager.delete(`/admin/v1/license/${licenseId}`);
};

export const postLicense = (request: any) => {
  return apiManager.post('/admin/v1/license', request);
};

export const patchLicense = (licenseId: string, request: any) => {
  return apiManager.patch(`/admin/v1/license/${licenseId}`, request);
};

export const getLicensesInquiry = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/license-inquiry', { params: queryParams });
};

export const patchLicenseInquiryComment = (licenseInquiryId: string, request: { comment: string }) => {
  return apiManager.patch(`/admin/v1/license-inquiry/comment/${licenseInquiryId}`, request);
};

export const patchOneStopServices = (oneStopServicesId: string, request: any) => {
  return apiManager.patch(`/admin/v1/one-stop-services/${oneStopServicesId}`, request);
};

export const patchProductInquiryComment = (productInquiryId: string, request: { comment: string }) => {
  return apiManager.patch(`/admin/v1/product-inquiry/${productInquiryId}`, request);
};

export const patchLicenseInquiry = (licenseInquiryId: number, request: { isCompleted: boolean }) => {
  return apiManager.patch(`/admin/v1/license-inquiry/${licenseInquiryId}`, request);
};

export const patchProductInquiryStatusChange = (productInquiryId: string, request: { status: string }) => {
  return apiManager.patch(`/admin/v1/product-inquiry/${productInquiryId}`, request);
};

export const postLogout = (request: any) => {
  return apiManager.post('admin/auth/logout', request);
};

export const postLogin = (request: any) => {
  return apiManager.post('/admin/auth/login', request);
};

export const fetchMemberData = async (id: number) => {
  return await apiManager.get(`/admin/v1/admin-members/${id}`);
};

export const getOneStopServices = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/one-stop-services', { params: queryParams });
};

export const getNotification = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/notifications', { params: queryParams });
};

export const postNotification = (request: any) => {
  return apiManager.post(`/admin/v1/notifications`, request);
};

export const deleteNotification = (notificationId?: string) => {
  return apiManager.delete(`/admin/v1/notifications/${notificationId}`);
};

export const patchNotification = (notificationId: string, request: any) => {
  return apiManager.patch(`/admin/v1/notifications/${notificationId}`, request);
};

export const getProductInquiry = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/product-inquiry', { params: queryParams });
};

export const patchProductPerchase = (productId: string, request: { salesType: string }) => {
  return apiManager.patch(`/admin/v1/products/purchase/${productId}`, request);
};
export const patchProductToConsignment = (productId: string, request: { salesPeopleId: string }) => {
  return apiManager.patch(`/admin/v1/products/consignment/${productId}`, request);
};

export const getMemberList = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/members', { params: queryParams });
};

export const patchMemberInfo = (memberId: string, request: { name: string; phoneNumber: string; email: string }) => {
  return apiManager.patch(`/admin/v1/members/${memberId}`, request);
};

export const patchBannerSort = (request: { bannerId: string; sortNumber: string }) => {
  return apiManager.patch(`/admin/v1/banners/sort`, request);
};

export const deleteMember = (memberId?: string) => {
  return apiManager.delete(`/admin/v1/members/${memberId}`);
};

export const getBannerList = (queryParams?: URLSearchParams) => {
  return apiManager.get(`/admin/v1/banners`, { params: queryParams });
};

export const postBanners = (request: any) => {
  return apiManager.post(`/admin/v1/banners`, request);
};

export const patchBanners = (id: string, request: any) => {
  return apiManager.patch(`/admin/v1/banners/${id}`, request);
};

export const getBannersDetail = (bannerId?: string) => {
  return apiManager.get(`/admin/v1/banners/${bannerId}`);
};

export const getJobList = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/job', { params: queryParams });
};

export const deleteJob = (jobId?: string) => {
  return apiManager.delete(`/admin/v1/job/${jobId}`);
};

export const getJobsDetail = (bannerId?: string) => {
  return apiManager.get(`/admin/v1/job/${bannerId}`);
};

export const postJobs = (request: SelectedJobValue) => {
  return apiManager.post(`/admin/v1/job`, request);
};

export const patchJobs = (id: string, request: any) => {
  return apiManager.patch(`/admin/v1/job/${id}`, request);
};

export const getPrices = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/prices', { params: queryParams });
};

export const getAdminMemberList = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/admin-members', { params: queryParams });
};

export const postAdminMember = (request: { loginId: string; name: string; phoneNumber: string }) => {
  return apiManager.post(`/admin/v1/admin-members`, request);
};
export const deleteAdminMember = (adminMemberId?: string) => {
  return apiManager.delete(`/admin/v1/admin-members/${adminMemberId}`);
};

export const updateAdminMember = (adminMemberId: string, request: any) => {
  return apiManager.patch(`/admin/v1/admin-members/${adminMemberId}`, request);
};

export const getTransferAgencyServices = (queryParam?: URLSearchParams) => {
  return apiManager.get('/admin/v1/transfer-agency-services', { params: queryParam });
};

export const patchTransferAgencyServices = (transferAgencyServiceId: string, request: any) => {
  return apiManager.patch(`/admin/v1/transfer-agency-services/${transferAgencyServiceId}`, request);
};

export const getContractsList = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/contracts', { params: queryParams });
};
export const getDelayProductsList = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/products/delay', { params: queryParams });
};

export const getSalesReport = () => {
  return apiManager.get('/admin/v1/dashboard/sales-report');
};
export const getFunctionActiveReport = () => {
  return apiManager.get('/admin/v1/dashboard/function-active-report');
};
export const getStatisticsReport = async (queryParams: DashboardChartRequestParams) => {
  const response = await apiManager.get('/admin/v1/dashboard/statistics', { params: queryParams });
  return response.data;
};
export const getDashboard = () => {
  return apiManager.get('/admin/v1/dashboard');
};
export const putDashboardAppDownloadCount = (request: { baseDay: string; appDownloadCount: string }) => {
  return apiManager.put('/admin/v1/dashboard/app-download-count', request);
};

export const getDashboardChart = async (queryParams: DashboardChartRequestParams) => {
  const response = await apiManager.get('/admin/v1/dashboard/chart', { params: queryParams });
  return response.data;
};

export const getPurchaseAccompanyingServices = (queryParam?: URLSearchParams) => {
  return apiManager.get('/admin/v1/purchase-accompanying-services', { params: queryParam });
};

export const patchPurchaseAccompanyingServices = (id: string, request: any) => {
  return apiManager.patch(`/admin/v1/purchase-accompanying-services/${id}`, request);
};

export const getAppDownloadCount = (queryParam?: URLSearchParams) => {
  return apiManager.get('/admin/v1/dashboard/app-download-count', { params: queryParam });
};

export const patchLicenseMemo = (licenseId: string, request: { memo: string }) => {
  return apiManager.patch(`/admin/v1/license/${licenseId}`, request);
};

export const getNotice = async (queryParam?: URLSearchParams) => {
  const res = await apiManager.get('/admin/v1/notice', { params: queryParam });
  return res.data;
};

export const deleteNotice = (noticeId?: string) => {
  return apiManager.delete(`/admin/v1/notice/${noticeId}`);
};

export const postNotice = (request: any) => {
  return apiManager.post(`/admin/v1/notice`, request);
};

export const patchNotice = (noticeId: string, request: any) => {
  return apiManager.patch(`/admin/v1/notice/${noticeId}`, request);
};

export const getTheunbanProducts = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/products/theunban', { params: queryParams });
};

export const postProductInvoicesForTheunban = (request: any) => {
  return apiManager.post(`/admin/v1/products/invoices/send`, request);
};

export const getMemberByKeyword = (queryParam?: URLSearchParams) => {
  return apiManager.get('/admin/v1/members/keyword', { params: queryParam });
};

export const deleteRedisCache = (redisKey?: string) => {
  return apiManager.delete(`/admin/v1/redis/cache/${redisKey}`);
};

export const getCapitalCounselServices = (queryParam?: URLSearchParams) => {
  return apiManager.get('/admin/v1/capital-counsel-services', { params: queryParam });
};

export const patchCapitalCounselServices = (capitalCounselServiceId: string, request: any) => {
  return apiManager.patch(`/admin/v1/capital-counsel-services/${capitalCounselServiceId}`, request);
};

export const getSubsidyForFuelStandard = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/subsidy-for-fuel-standard', { params: queryParams });
};

export const postSubsidyForFuelStandard = (request: any) => {
  return apiManager.post('/admin/v1/subsidy-for-fuel-standard', request);
};

// 모델 관리
export const getModels = (queryParam?: URLSearchParams) => {
  return apiManager.get('/admin/v1/models', { params: queryParam });
};

export const postModels = (request: any) => {
  return apiManager.post('/admin/v1/models', request);
};

export const patchModels = (modelId: number, request: any) => {
  return apiManager.patch(`/admin/v1/models/${modelId}`, request);
};

export const deleteModels = (modelId?: string) => {
  return apiManager.delete(`/admin/v1/models/${modelId}`);
};

export const getTerms = async (queryParam?: URLSearchParams) => {
  const res = await apiManager.get('/admin/v1/terms', { params: queryParam });
  return res.data;
};

export const postTerms = (request: any) => {
  return apiManager.post(`/admin/v1/terms`, request);
};

export const patchTerms = (termsId: string, request: any) => {
  return apiManager.patch(`/admin/v1/terms/${termsId}`, request);
};

export const getMeta = () => {
  return apiManager.get('/admin/v1/meta');
};

export const getGoogleReviews = (queryParams?: URLSearchParams) => {
  return apiManager.get('/admin/v1/google-reviews', { params: queryParams });
};
export const postGoogleReviewsReply = (googleReviewId: string, request: any) => {
  return apiManager.post(`/admin/v1/google-reviews/${googleReviewId}/reply`, request);
};
export const patchPrices = (priceId: string, request: any) => {
  return apiManager.patch(`/admin/v1/prices/${priceId}`, request);
};
